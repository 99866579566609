<script>
  import MediaObject from "../../../common/DataDisplay/MediaObject.svelte";
  import Input from "../../../common/control/Input.svelte";
  import Select from "../../../common/control/Select.svelte";
  import Button from "../../../common/control/Button.svelte";
  import CheckList from "../../../common/control/CheckList.svelte";
  import TextArea from "../../../common/control/TextArea.svelte";
  import PhoneInput from "../../../common/control/PhoneInput.svelte";
  import Header from "../../../common/Header.svelte";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let id;
  export let image = "";
  export let name;
  export let email = "";
  export let gender;
  export let phone;
  export let password = "";
  export let address;
  export let errors;
  export let roles = [];
  export let repassword = "";

  let errorRoles = false;

  let genders = [
    { id: "M", name: `Male` },
    { id: "F", name: `Female` },
    { id: "O", name: `Other` },
  ];

  let roleItems = [
    { text: "Store Manager", value: "store-manager", index: 1 },
    { text: "Employee", value: "employee", index: 2 },
    { text: "Customer", value: "customer", index: 3 },
    { text: "Administrator Lite", value: "admin-lite", index: 4 },
  ];

  function handleSubmit() {
    if (roles.length == 0) {
      errorRoles = true;
    } else {
      errorRoles = false;
      dispatch("submit", {
        image,
        name,
        email,
        gender,
        phone,
        password,
        address,
        roles,
      });
    }
  }
</script>
<Header title={id ? "Edit User" : "Add User"}>
  <Button leftIcon="bx bx-save" type="submit" on:click={() => handleSubmit()}>Save Changes</Button> 
</Header>
<div class="p-4">
  <MediaObject
    bind:image
    title="Profile Image"
    description="(jpg, png, jpeg)"
    size="medium"
    actionLabel="Upload Image"
    actionType="file"
    on:click={(e) => (image = e.detail)}
  />

  <div
    class="flex flex-col md:flex-row space-y-2 md:space-y-0 mt-5 space-x-0 md:space-x-2"
  >
    <div class="flex-1">
      <Input bind:errors label="Name" bind:value={name} name="name" />
    </div>
    <div class="flex-1">
      <Input
        bind:errors
        label="Email"
        type="email"
        bind:value={email}
        name="email"
        disabled={id ? true : false}
      />
    </div>
  </div>

  <div
    class="flex flex-col md:flex-row mt-5 space-y-2 md:space-y-0 space-x-0 md:space-x-2"
  >
    <div class="flex-1">
      <Select
        bind:errors
        name="gender"
        textHint="Choose Gender"
        options={genders}
        label="Gender"
        selected={gender}
        fullWidth
        on:change={(e) => {
          gender = e.detail;
        }}
      />
    </div>
    <div class="flex-1">
      <PhoneInput bind:errors label="Phone" bind:value={phone} name="phone" />
    </div>
  </div>

  {#if !id}
    <div
      class="flex flex-col md:flex-row mt-5 space-y-2 space-x-0 md:space-x-2 md:space-y-0"
    >
      <div class="flex-1">
        <Input
          bind:errors
          label="Password"
          type="password"
          bind:value={password}
          name="password"
        />
      </div>
      <div class="flex-1">
        <Input
          bind:errors
          label="Re-type Password"
          type="password"
          bind:value={repassword}
          name="repassword"
        />
      </div>
    </div>
  {/if}

  <div
    class="flex flex-col md:flex-row mt-5 space-y-2 md:space-y-0 space-x-0 md:space-x-2"
  >
    <div class="flex-1">
      <TextArea
        bind:errors
        name="address"
        label="Address"
        bind:value={address}
        placeholder="ex: street soekarno hatta"
        maxlength={200}
      />
    </div>
    <div class="flex-1">
      <label class="block text-primary text-sm font-bold mb-2" for="role"
        >Roles</label
      >
      <div class={errorRoles ? "border-red-500 border" : "grid grid-cols-2"}>
        <CheckList
          items={roleItems}
          checkedItems={roles}
          on:change={(e) => {
            const r = e.detail.selected;
            if (roles.includes(r)) {
              roles.splice(
                roles.findIndex((item) => r == item),
                1
              );
            } else roles.push(r);

            roles = roles;
          }}
        />
      </div>
      {#if errorRoles}
        <p class="text-red-500 text-xs italic">Please select one role.</p>
      {/if}
    </div>
  </div>
</div>
