<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();
	export let data;
	export let hasStatusOnChild = false;
	let del = false;

	function onClickItem(id) {
		if (!del) {
			const accordionHeader = document.querySelectorAll(`.accordion-header-${id}`);
			accordionHeader.forEach((header) => {
				const accordionContent = header.parentElement.querySelector(".accordion-content");
				let accordionMaxHeight = accordionContent.style.maxHeight;

				// Condition handling
				if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
					accordionContent.style.maxHeight = `${accordionContent.scrollHeight + 32}px`;
					header.querySelector(".bx-chevron-down").style.transform = "rotate(180deg)";
				} else {
					accordionContent.style.maxHeight = `0px`;
					header.querySelector(".bx-chevron-down").style.transform = "rotate(0deg)";
				}
			});
		} else {
			del = false;
		}
	}
</script>

{#each data as item, i}
	<div class="place-items-center">
		<div class="w-full transition">
			<div
				class={`accordion-header-${item._id} bg-gray-100 cursor-pointer border-b relative transition flex justify-between p-3 items-center h-160`}
				on:click={() => onClickItem(item._id)}
			>
				<h3 class="font-medium">{item.name}</h3>
				<div class="flex items-center justify-center space-x-3">
					<div class="rounded-md {item.status == 'ACTIVE' ? 'bg-green-500' : 'bg-red-500'} text-white px-3 text-xs py-1">
						{item.status}
					</div>
					<i class={"bx bx-chevron-down"} />
					<button
						on:click={() => {
							del = true;
							dispatch("delete", item);
						}}><i class={"bx bx-trash text-red-500"} /></button
					>
				</div>
			</div>
			<div class="accordion-content pt-0 overflow-hidden max-h-0">
				{#each item.products as product}
					<div class={hasStatusOnChild ? "flex justify-between items-center" : ""}>
						<p class="text-sm font-medium py-2 px-3 text-justify text-gray-600">{product.name}</p>
						{#if hasStatusOnChild}
							<p class="text-sm font-medium py-2 px-3 text-justify text-gray-600">{product.status}</p>
						{/if}
					</div>
				{/each}
			</div>
		</div>
	</div>
{/each}

<style>
	.accordion-content {
		transition:
			max-height 0.3s ease-out,
			padding 0.3s ease;
	}
</style>
