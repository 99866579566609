<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/DetailTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

	export let params = {};

	const { _id } = params;
	const dispatch = createEventDispatcher();
	let isLoading;
	let dataId;
	let data;

	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("products.byId.forAdminLite", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}

	function updateProductActiveInApp(activeInApp) {
		isLoading = true;
		$connection
			.call("products.update.status.for.mobile", dataId, activeInApp)
			.then((res) => {
				if (res["code"] && res["code"] == 200 && activeInApp) {
					window.pushToast("Product is now Enabled in Mobile App", "success");
				} else if (res["code"] && res["code"] == 200 && !activeInApp) {
					window.pushToast("Product is now Disabled in Mobile App", "success");
				}
			})
			.catch((error) => {
				window.pushToast("Something is wrong", "danger");
				console.log(error);
			});
		isLoading = false;
		loadData(dataId);
	}
	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	function handleReloadData() {
		loadData(dataId);
		dispatch("routeEvent");
	}
</script>

<Layout id={dataId} name={data?.name} page="detail">
	{#if isLoading}
		<LoadingText />
	{:else if data.ingredients != undefined}
		<Template
			id={dataId}
			{data}
			on:reload-data={handleReloadData}
			on:display-in-mobile={(e) => updateProductActiveInApp(e.detail)}
			on:disable-in-mobile={(e) => updateProductActiveInApp(e.detail)}
		/>
	{/if}
</Layout>
