<script>
	import { onMount } from "svelte";
	import Button from "../../../common/control/Button.svelte";
	import Loading from "../../../common/Loading.svelte";
	import LoadingText from "../../../common/LoadingText.svelte";
	import Tile from "../../../common/Tile.svelte";
	import { connection } from "../../../store";

	export let id;
	let productCategoryList = [];
	let isSortingProductCategoryExist;
	let disableSaveButton = true;
	let orderAction = {
		Up: "up",
		Down: "down",
	};
	let loadingProductCategories = false;
	let isLoading;
	let totalProductCategories = 0;

	onMount(() => {
		loadData();
	});

	async function loadData() {
		// reset value
		productCategoryList = [];
		loadingProductCategories = true;
		$connection
			.call("stores.productCategories", id)
			.then((result) => {
				productCategoryList = result.storeProductCategories;
				totalProductCategories = productCategoryList.length;
				isSortingProductCategoryExist = result.isSortingProductCategoryExist;
			})
			.catch((err) => {
				window.pushToast(`${err.reason}`, "danger");
			})
			.finally(() => {
				loadingProductCategories = false;
			});
	}
	function editProductCategoryOrder(action, index) {
		disableSaveButton = false;

		// Edge cases: if it's the first or last element, don't do anything
		if (action === orderAction.Up && index > 0) {
			// Move up: Swap with the previous item
			[productCategoryList[index - 1], productCategoryList[index]] = [productCategoryList[index], productCategoryList[index - 1]];
		} else if (action === orderAction.Down && index < productCategoryList.length - 1) {
			// Move down: Swap with the next item
			[productCategoryList[index + 1], productCategoryList[index]] = [productCategoryList[index], productCategoryList[index + 1]];
		}
	}

	async function saveProductCategoryOrder() {
		loadingProductCategories = true;
		disableSaveButton = true;
		const updatedProductCategoryOrder = productCategoryList.map((c) => c._id);
		$connection
			.call("stores.productCategories.update.order", id, updatedProductCategoryOrder, isSortingProductCategoryExist)
			.then((_result) => {
				window.pushToast("Successfully Saved Category Sorting", "success");
			})
			.catch((err) => {
				window.pushToast(`${err.reason}`, "danger");
			})
			.finally(() => {
				loadData();
			});
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />

<Tile title="Total Categories" info={totalProductCategories} />
<div class="flex justify-between items-center my-4">
	<h4 class="text-md font-medium">Category Sorting</h4>
</div>

{#if loadingProductCategories}
	<LoadingText />
{:else if !productCategoryList.length}
	<p class="py-4 whitespace-nowrap text-sm text-gray-500">No Category Available</p>
{:else}
	<div>
		{#each productCategoryList as category, index}
			<div class="place-items-center">
				<div class="w-full transition">
					<div class="bg-gray-100 border-b transition flex justify-between px-3 py-2 items-center">
						<div class="flex justify-start gap-4 items-center">
							<div class="flex items-center leading-none">
								<!-- svelte-ignore a11y-click-events-have-key-events -->
								<i
									class={`bx bx-chevron-down text-3xl  text-primary relative bottom-0.5 ${
										index == productCategoryList.length - 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
									}`}
									 on:click={() => {
                                        if (index != productCategoryList.length - 1) {
                                            editProductCategoryOrder(orderAction.Down, index);
                                        }
                                    }}
								/>
								<!-- svelte-ignore a11y-click-events-have-key-events -->
								<i
									class={`bx bx-chevron-up text-3xl text-primary ${
										index == 0 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
									}`}
									on:click={() => {
                                        if (index != 0) {
                                            editProductCategoryOrder(orderAction.Up, index);
                                        }
                                    }}
								/>
							</div>
							<h3 class="font-medium">{category.name}</h3>
						</div>
						<div class="flex items-center justify-center space-x-3">
							<div
								class="rounded-md {category.status == 'ACTIVE'
									? 'bg-green-500'
									: 'bg-red-500'} text-white px-3 text-xs py-1 w-20 text-center"
							>
								{category.status}
							</div>
						</div>
					</div>
				</div>
			</div>
		{/each}
		<div class="mt-4">
			<Button
				extClass="w-full"
				status="success"
				disabled={disableSaveButton}
				on:click={() => {
					saveProductCategoryOrder();
				}}
			>
				Save Changes
			</Button>
		</div>
	</div>
{/if}
