<script>
  import Button from "../../../common/control/Button.svelte";
  import { push, replace } from "svelte-spa-router";
  import Modal from "../../../common/Modal.svelte";
  import LoadingText from "../../../common/LoadingText.svelte";
  import { connection } from "../../../store";
	import { createEventDispatcher } from "svelte";

  export let id;
  export let data;

  let modalConfirmDelete;
	const dispatch = createEventDispatcher();

  function handleDelete() {
    $connection
      .call("stores.remove", id)
      .then((res) => {
        setTimeout(() => {
          push("/stores");
        }, 3000);
        window.pushToast("Successfully Deleted Store.", "success");
        modalConfirmDelete.toggle();
      })
      .catch((err) => {
        window.pushToast("Something Wrong! Cant deleted Store.", "danger");
      });
  }
</script>

<Modal
  size="small"
  title="Delete Store"
  confirm
  bind:this={modalConfirmDelete}
  on:submit={handleDelete}
  on:cancel={() => {
    modalConfirmDelete.toggle();
  }}
/>
{#if !data}
  <LoadingText />
{:else}
  <div class="flex flex-col">
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Name</div>
      <div class="flex items-center flex-1">: {data?.name}</div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">
        Prefix Transaction Midtrans
      </div>
      <div class="flex items-center flex-1">: {data?.prefix ?? "-"}</div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Phone</div>
      <div class="flex items-center flex-1">
        : +{data?.phone?.code}
        {data?.phone?.number}
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Address</div>
      <div class="flex items-center flex-1">: {data?.address}</div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Latitude</div>
      <div class="flex items-center flex-1">
        : {data?.location?.lat != "" ? data?.location?.lat : "-"}
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Longitude</div>
      <div class="flex items-center flex-1">
        : {data?.location?.long != "" ? data?.location?.long : "-"}
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Tax</div>
      <div class="flex items-center flex-1">
        : {data?.tax == 0 ? data?.tax : data?.tax || 10}%
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">
        Service Charge
      </div>
      <div class="flex items-center flex-1">
        : {data?.serviceCharge == 0
          ? data?.serviceCharge
          : data?.serviceCharge || 15}%
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Rounding</div>
      <div class="flex items-center flex-1">: {data?.rounding || "UP"}</div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Status</div>
      <div class="flex items-center flex-1">
        : {data?.status == "ACTIVE" ? "Active" : "InActive"}
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">
        Is Store Open?
      </div>
      <div class="flex items-center flex-1">
        : {data?.isOpen ? "Open" : "Closed"}
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Discounts</div>
      <div class="flex items-center flex-1 overflow-x-auto">
        : {#if data?.discounts && data?.discounts.length > 0}
          {#each data?.discounts.sort((a, b) => a - b) as disc}
            <p class="px-4 rounded-full border border-gray-800 mr-1 ml-1">
              {disc}%
            </p>
          {/each}{:else}
          -
        {/if}
      </div>
    </div>
    <!-- <div class="flex  items-center">
        <div class="flex items-center w-1/6 text-sm text-gray-500">Manager</div>
        <div class="flex items-center flex-1">: {data?.manager?.name}</div>
    </div> -->
    <div class="flex items-center">
      <div class="flex items-center w-1/6 text-sm text-gray-500">
        Description
      </div>
      <div class="flex items-center flex-1">
        {#if data?.desc}
          <span>: {data.desc}</span>
        {:else}
          <span class="text-gray-300">: No Description</span>
        {/if}
      </div>
    </div>
    {#if data?.operationalHours}
      <div class="flex flex-col mt-4">
        <div class="flex w-1/6 text-sm text-gray-500 mb-2">
          Operational Hours :
        </div>
        <div class="flex flex-col flex-1 space-y-2">
          <div class="flex">
            <div class="flex-1">
              <p class="text-sm text-gray-500">Day</p>
            </div>
            <div class="flex-1">
              <p class="text-sm text-gray-500">Open</p>
            </div>
            <div class="flex-1">
              <p class="text-sm text-gray-500">Closed</p>
            </div>
          </div>
          {#each data?.operationalHours as op}
            <div class="flex items-center">
              <div class="flex-1">
                <p class="text-sm">
                  {op.day == "thrusday" ? "THURSDAY" : op.day.toUpperCase()}
                </p>
              </div>
              <div class="flex-1">
                <p class="text-sm">{op.open != "" ? op.open : "-"}</p>
              </div>
              <div class="flex-1">
                <p class="text-sm">{op.closed != "" ? op.closed : "-"}</p>
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>
{/if}

<div class="absolute mr-3 mt-2 top-0 right-0 flex space-x-2">
  <Button leftIcon="bx bx-edit" on:click={push(`/stores/${id}/edit`)}
    >Edit</Button
  >
  <Button leftIcon="bx bx-trash" on:click={() => modalConfirmDelete.toggle()}
    >Delete</Button
  >
  <Button
    leftIcon="bx bx-notification"
    on:click={() => {
      $connection
        .call("stores.activate", id)
        .then((res) => {
		      dispatch("refreshData");
          window.pushToast("Store now Activated.", "success");
        })
        .catch((err) => {
          window.pushToast(err.reason, "error");
        });
    }}>Activate</Button
  >
  <Button
    leftIcon="bx bx-notification-off"
    on:click={() => {
      $connection
        .call("stores.deactivate", id)
        .then((res) => {
		      dispatch("refreshData");
          window.pushToast("Store now Deactivated.", "success");
        })
        .catch((err) => {
          window.pushToast(err.reason, "error");
        });
    }}>Deactivate</Button
  >
</div>
