<script>
	import Template from "./templates/ProductCategoriesTemplate.svelte";
	import Layout from "./templates/LayoutTemplate.svelte";
	export let params = {};

	let dataId;

	$: if (params) {
		dataId = params._id;
	}
</script>

<Layout id={dataId} page="categories">
	<Template id={dataId} />
</Layout>
