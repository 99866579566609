<script>
	import Template from "./templates/DetailTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";
	export let params = {};

	const { _id } = params;
	let isLoading;
	let dataId;
	const dispatch = createEventDispatcher();
	let data;

	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("products.additionals.byId.forAdminLite", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	// refresh data on list page
	function routeEvent() {
		dispatch("routeEvent");
	}
	function activateProductAdditional(id) {
		isLoading = true;
		$connection
			.call("products.additionals.activate", id)
			.then(() => {
				loadData(dataId);
				window.pushToast("Modifier is now Activated", "success");
				routeEvent();
			})
			.catch((error) => {
				console.log(error);
			});

		isLoading = false;
	}

	function deactivateProductAdditional(id) {
		isLoading = true;
		$connection
			.call("products.additionals.deactivate", id)
			.then(() => {
				loadData(dataId);
				window.pushToast("Modifier is now Deactivated", "success");
				routeEvent();
			})
			.catch((error) => {
				console.log(error);
			});

		isLoading = false;
	}
</script>

{#if isLoading}
	<LoadingText />
{:else if data}
	<Template
		id={dataId}
		{data}
		on:reload-data={routeEvent}
		on:activated={(e) => activateProductAdditional(e.detail)}
		on:deactivated={(e) => deactivateProductAdditional(e.detail)}
	/>
{/if}
