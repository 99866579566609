<script>
  import { fade } from "svelte/transition";
  import { push, replace } from "svelte-spa-router";
  import Router from "svelte-spa-router";
  import Layout from "../../common/layout/LayoutSidebar.svelte";
  import DataList from "../../common/DataDisplay/List.svelte";
  import SearchBar from "../../common/SearchBar.svelte";

  import List from "./List.svelte";
  import Add from "./Add.svelte";
  import Edit from "./Edit.svelte";
  import Info from "./Info.svelte";
  import Employees from "./Employees.svelte";
  import Menus from "./Menus.svelte";

  import { onMount } from "svelte";
  import { connection } from "../../store";
  import LoadingText from "../../common/LoadingText.svelte";
  import Products from "./Products.svelte";
  import Supplies from "./Supplies.svelte"
  import Stockopname from "./Stockopname.svelte"
	import ProductCategory from "./ProductCategory.svelte";

  export let params = {};

  const prefix = "/stores"; 
  const routes = {
    "/": List,
    "/add": Add,
    "/:_id/edit": Edit,
    "/:_id/info": Info,
    "/:_id/employees": Employees,
    "/:_id/products": Products,
    '/:_id/menus': Menus,
    '/:_id/supplies': Supplies,
    '/:_id/stockopname': Stockopname,
    '/:_id/categories': ProductCategory,
  };

  let isLoading = true;
  let search = "";
  $: dataId = params.wild?.split("/")[0];
  let locationCollection = $connection.collection("locations").reactive();
  $: data = locationCollection.data();
  $: dataFiltered = fetchStoreData(data);

  $m: {
    let storeSub = $connection.subscribe("stores.all.forList");
    storeSub.ready();

    locationCollection.onChange((newData) => {
      dataFiltered = fetchStoreData(newData);
    });
  }

  onMount(() => {
    loadData();
  });

  function fetchStoreData(storeData) {
    let tempData = [
      {
        category: `Active (${
          storeData.filter((e) => e["status"] == "ACTIVE").length
        })`,
        items: storeData
          .filter((e) => e["status"] == "ACTIVE")
          .map((f) => storeMap(f)),
      },
      {
        category: `InActive (${
          storeData.filter((e) => e["status"] == "INACTIVE").length
        })`,
        items: storeData
          .filter((e) => e["status"] == "INACTIVE")
          .map((f) => storeMap(f)),
      },
    ];

    return tempData;
  }

  async function loadData() {
    isLoading = false;
  }

  function storeMap(store) {
    return {
      _id: store.id,
      title: store.name,
      desc: store.address,
      icon: "bx bx-store",
      active: store.id == dataId,
    };
  }

  function handleSearch(e) {
    const keyword = e.detail.trim().toLowerCase();

    let dataTemp = data.filter((e) =>
      keyword ? e["name"].toLowerCase().includes(keyword) : true
    );

    let tempData = [
      {
        category: `Active (${
          dataTemp.filter((e) => e["status"] == "ACTIVE").length
        })`,
        items: dataTemp
          .filter((e) => e["status"] == "ACTIVE")
          .map((f) => storeMap(f)),
      },
      {
        category: `InActive (${
          dataTemp.filter((e) => e["status"] == "INACTIVE").length
        })`,
        items: dataTemp
          .filter((e) => e["status"] == "INACTIVE")
          .map((f) => storeMap(f)),
      },
    ];

    dataFiltered = tempData;
  }

  function handleItemClick(e) {
    dataId = e.detail._id;
    localStorage.setItem(
      "storeName",
      data.filter((e) => e["id"] == dataId)[0]["name"]
    );
    localStorage.setItem(
      "storeAddress",
      data.filter((e) => e["id"] == dataId)[0]["address"]
    );
    push(`${prefix}/${dataId}/info`);
  }

  $: if (dataId && dataFiltered.length > 0) {
    dataFiltered = dataFiltered.map((store) => ({
      ...store,
      items: store.items.map((f) => ({ ...f, active: f._id == dataId })),
    }));
  }
</script>

<Layout title="Stores">
  <div class="flex flex-col md:flex-row md:h-full">
    <div class="flex flex-col md:h-full w-full md:w-1/4 py-3 pl-3 pr-1.5">
      <SearchBar
        value={search}
        on:input={handleSearch}
        actionOne
        m,m
        um
        on:click-one={() => push(`${prefix}/add`)}
      />
      {#if isLoading}
        <LoadingText />
      {:else}
        <DataList
          category={true}
          bind:items={dataFiltered}
          on:item-click={(e) => handleItemClick(e)}
        />
      {/if}
    </div>
    <div
      class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5"
      in:fade={{ duration: 500 }}
    >
      <div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
        <Router {routes} {prefix} />
      </div>
    </div>
  </div>
</Layout>
